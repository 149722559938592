<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Tambah Unit" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="tab-text">
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-1/4 w-full -mt-1">
              <label class="ml-1 text-xs opacity-75">Proyek *</label>
              <v-select :options="proyeks" @input="onChangeProyek" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_proyek"/>
            </div>
            <div class="vx-col sm:w-1/4 w-full -mt-1">
              <label class="ml-1 text-xs opacity-75">Properti Kategori *</label>
              <v-select :options="properti_kategoris" @input="onChangeKategori" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_properti_kategori"/>
            </div>
            <div class="vx-col sm:w-1/4 w-full -mt-1">
              <label class="ml-1 text-xs opacity-75">Properti Tipe *</label>
              <v-select :options="properti_tipes" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_properti_tipe"/>
            </div>
            <div class="vx-col sm:w-1/4 w-full -mt-1">
              <label class="ml-1 text-xs opacity-75">Cluster *</label>
              <v-select :options="clusters" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_cluster"/>
            </div>
          </div>
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-1/4 w-full">
              <vs-input class="w-full" label-placeholder="No.Unit *" v-model="data.no_unit"/>
            </div>
            <div class="vx-col sm:w-1/4 w-full">
              <vs-input class="w-full" label-placeholder="Luas Bangunan M2 *" v-model="data.luas_bangunan"/>
            </div>
            <div class="vx-col sm:w-1/4 w-full">
              <vs-input class="w-full" label-placeholder="Luas Tanah M2 *" v-model="data.luas_tanah"/>
            </div>
            <div class="vx-col sm:w-1/4 w-full">
              <vs-input class="w-full" label-placeholder="Luas Efektif *" v-model="data.luas_efektif"/>
            </div>
          </div>
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-6/12 w-full">
              <vs-input class="w-full" label-placeholder="View" v-model="data.view"/>
            </div>
            <div class="vx-col sm:w-6/12 w-full">
              <vs-input class="w-full" label-placeholder="Lokasi" v-model="data.lokasi"/>
            </div>
          </div>
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-1/2 w-full">
              <vs-input class="w-full" label-placeholder="Keterangan" v-model="data.keterangan"/>
            </div>
            <div class="vx-col sm:w-1/2 w-full">
              <vs-input class="w-full" label-placeholder="Spesifikasi" v-model="data.spesifikasi"/>
            </div>
          </div>

          <vs-divider> Harga Unit </vs-divider>

          <div class="vx-row mb-3">
            <div class="vx-col sm:w-2/12 w-full">
              <label class="ml-1 text-xs">HPP Tanah *</label>
              <v-money class="w-full" v-model="data.hpp_tanah"/>
            </div>
            <div class="vx-col sm:w-2/12 w-full">
              <label class="ml-1 text-xs">HPP Bangunan *</label>
              <v-money class="w-full" v-model="data.hpp_bangunan"/>
            </div>
            <div class="vx-col sm:w-4/12 w-full">
              <label class="ml-1 text-xs">Total HPP</label>
              <v-money class="w-full" :value="totalHpp" readonly/>
            </div>
            <div class="vx-col sm:w-2/12 w-full">
              <!-- Default Harga Standar Tanah = ambil dari "HPP Tanah" tapi bisa diubah-->
              <label class="ml-1 text-xs">Harga Standar Tanah *</label>
              <v-money class="w-full" v-model="data.harga_standar_tanah"/>
            </div>
            <div class="vx-col sm:w-2/12 w-full">
              <!-- Default Harga Standar Tanah = ambil dari "HPP Bangunan" tapi bisa diubah-->
              <label class="ml-1 text-xs">Harga Standar Bangunan *</label>
              <v-money class="w-full" v-model="data.harga_standar_bangunan"/>
            </div>
          </div>
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-2/12 w-full">
              <label class="ml-1 text-xs">Harga Luas</label>
              <v-money class="w-full" v-model="data.harga_luas"/>
            </div>
            <div class="vx-col sm:w-2/12 w-full">
              <label class="ml-1 text-xs">Harga Tinggi</label>
              <v-money class="w-full" v-model="data.harga_tinggi"/>
            </div>
            <div class="vx-col sm:w-2/12 w-full">
              <label class="ml-1 text-xs">Harga View</label>
              <v-money class="w-full" v-model="data.harga_view"/>
            </div>
            <div class="vx-col sm:w-2/12 w-full">
              <label class="ml-1 text-xs">Harga Mutu</label>
              <v-money class="w-full" v-model="data.harga_mutu"/>
            </div>
            <div class="vx-col sm:w-2/12 w-full">
              <label class="ml-1 text-xs">Biaya Adm</label>
              <v-money class="w-full" v-model="data.biaya_adm"/>
            </div>
            <div class="vx-col sm:w-2/12 w-full">
              <label class="ml-1 text-xs">Biaya Notaris</label>
              <v-money class="w-full" v-model="data.biaya_notaris"/>
            </div>
          </div>
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-4/12 w-full">
              <label class="ml-1 text-xs">Total Harga Jual *</label>
              <v-money class="w-full" :value="totalHargaJual" disabled/>
            </div>
            <div class="vx-col sm:w-4/12 w-full">
              <label class="ml-1 text-xs">Harga Min *</label>
              <v-money class="w-full" v-model="data.harga_min"/>
            </div>
            <div class="vx-col sm:w-4/12 w-full">
              <label class="ml-1 text-xs">Harga Max *</label>
              <v-money class="w-full" v-model="data.harga_max"/>
            </div>
          </div>

        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import PropertiKategoriRepository from '@/repositories/master/properti-kategori-repository'
import PropertiTipeRepository from '@/repositories/master/properti-tipe-repository'
import PropertiClusterRepository from '@/repositories/master/cluster-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import VMoney from '@/views/components/v-money/VMoney'
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  name: 'PropertiUnitAdd',
  props: ['isActive'],
  components: {
    ValidationErrors,
    VMoney,
    'v-select': vSelect
  },
  mounted () {
    this.getAllProyek()
    this.getAllCluster()
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      proyeks: [],
      properti_kategoris: [],
      properti_tipes: [],
      clusters: [],
      data: {
        id_proyek: null,
        id_properti_kategori: null,
        id_properti_tipe: null,
        id_cluster: null,
        hpp_tanah: 0,
        hpp_bangunan: 0,
        harga_standar_tanah: 0,
        harga_standar_bangunan: 0,
        harga_luas: 0,
        harga_tinggi: 0,
        harga_view: 0,
        harga_mutu: 0,
        biaya_adm: 0,
        biaya_notaris: 0,
        harga_min: 0,
        harga_max: 0
      }
    }
  },
  computed: {
    totalHpp () {
      return (this.data.hpp_tanah || 0) + (this.data.hpp_bangunan || 0)
    },
    totalHargaJual () {
      return (
        (this.data.harga_standar_tanah || 0) +
        (this.data.harga_standar_bangunan || 0) +
        (this.data.harga_luas || 0) +
        (this.data.harga_tinggi || 0) +
        (this.data.harga_view || 0) +
        (this.data.harga_mutu || 0) +
        (this.data.biaya_adm || 0) +
        (this.data.biaya_notaris || 0)
      )
    }
  },
  watch: {
    totalHargaJual (totalHargaJual) {
      const defaultHargaUnitMaxPersen = this.$systemParam('unit_harga_max_persen')
      const defaultHargaUnitMinPersen = this.$systemParam('unit_harga_min_persen')
      this.data.harga_max = totalHargaJual + (totalHargaJual * defaultHargaUnitMaxPersen / 100)
      this.data.harga_min = totalHargaJual - (totalHargaJual * defaultHargaUnitMinPersen / 100)
    }
  },
  methods: {
    onChangeProyek () {
      this.properti_kategoris = []
      this.properti_tipes = []
      this.data.id_properti_kategori = null
      this.data.id_properti_tipe = null
      this.getPropertiKategoriByProyek()
    },

    onChangeKategori () {
      this.properti_tipes = []
      this.data.id_properti_tipe = null
      this.getAllPropertiTipeByKategori()
    },

    getPropertiKategoriByProyek () {
      const idProyek = this.data.id_proyek
      PropertiKategoriRepository.getPropertiKategoriByProyek(idProyek)
        .then(response => {
          this.properti_kategoris = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllProyek () {
      ProyekRepository.getAll()
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllPropertiTipeByKategori () {
      const idKategori = this.data.id_properti_kategori
      PropertiTipeRepository.getAllPropertiTipeByKategori(idKategori)
        .then(response => {
          this.properti_tipes = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllCluster () {
      PropertiClusterRepository.getAllCluster()
        .then(response => {
          this.clusters = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = {
        ...this.data,
        total_hpp: this.totalHpp,
        total_harga_jual: this.totalHargaJual
      }
      PropertiUnitRepository.create(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks', 'properti_kategoris', 'properti_tipes', 'clusters'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
